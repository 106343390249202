import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Inicio from './components/Inicio';
import Empresa from './components/Empresa';
import Contacto from './components/Contacto';
import NotFound from './components/NotFound';
import Galeria from './components/Galeria';

function App() {
	return (
		<div className='App'>
			<BrowserRouter>
				<Header />
				<Routes>
					<Route path='/' element={<Inicio />} />
					<Route path='empresa' element={<Empresa />} />
					<Route path='contacto' element={<Contacto />} />
					<Route path='galeria' element={<Galeria />} />
					<Route path='*' element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
