import logo from '../assets/img/logo.png';
import './Header.scss';
import React from 'react';
import { NavLink, Link } from 'react-router-dom';

const Header = () => {
	return (
		<header className='header'>
			<Link className='logo' to='/'>
				<img src={logo} alt='OMTech Logo' />
			</Link>
			<nav>
				<NavLink
					to='/'
					className={({ isActive }) =>
						isActive ? 'navlink-active' : undefined
					}
				>
					Inicio
				</NavLink>
				<NavLink
					to='/empresa'
					className={({ isActive }) =>
						isActive ? 'navlink-active' : undefined
					}
				>
					Empresa
				</NavLink>
				<NavLink
					to='/galeria'
					className={({ isActive }) =>
						isActive ? 'navlink-active' : undefined
					}
				>
					Galería
				</NavLink>
				<NavLink
					to='/contacto'
					className={({ isActive }) =>
						isActive ? 'navlink-active' : undefined
					}
				>
					Contacto
				</NavLink>
			</nav>
		</header>
	);
};

export default Header;
