import React from 'react';
import './Contacto.scss';

const Contacto = () => {
	return (
		<section className='contacto'>
			<div className='contacto__container'>
				<h1 className='contacto__main-title'>
					<span>OM</span>TECH
				</h1>
				<p>
					<span>Teléfono 1: </span>
					<a className='phone' href='tel:+34637859089'>
						{' '}
						+34 637 859 089
					</a>
				</p>
				<p>
					<span>Teléfono 2: </span>
					<a className='phone' href='tel:+34669642082'>
						{' '}
						+34 669 642 082
					</a>
				</p>
				<p>
					<span>Email:</span>
					<a className='email' href='mailto:toniral@hotmail.com'>
						{' '}
						omtechvigo@hotmail.com
					</a>
				</p>
			</div>
		</section>
	);
};

export default Contacto;
