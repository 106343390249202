import React from 'react';
import './Galeria.scss';

const Galeria = () => {
	const projects = [];

	for (let i = 1; i <= 6; i++) {
		projects.push({ name: i });
	}

	return (
		<section className='galeria'>
			<div className='images-container'>
				{projects.map((project) => (
					<div className='img-container'>
						<img
							src={require(`../assets/img/galeria/${project.name}.jpg`)}
							alt={project.name}
							id={project.name}
						/>
					</div>
				))}
			</div>
		</section>
	);
};

export default Galeria;
