import React from 'react';
import { Link } from 'react-router-dom';

import cover from '../assets/img/cover.jpg';
import './Inicio.scss';

const Inicio = () => {
	return (
		<section className='inicio'>
			<img src={cover} alt='Motor' className='cover' />

			<section className='content_container'>
				<h1 className='main_title'>
					<span>OM</span>Tech
				</h1>
				<h3 className='subtitle'>Mecánica Naval e Industrial</h3>
				<Link to='/empresa' className='primary_button'>
					Conócenos
				</Link>
			</section>
		</section>
	);
};

export default Inicio;
