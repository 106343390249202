import React from 'react';
import './NotFound.scss';

const NotFound = () => {
    return (
        <div className="NotFound-container">
            <h1>Error <span>404</span></h1>
            <h2>Página no encontrada</h2>
        </div>
    );
};

export default NotFound;