import React from 'react';
import './Empresa.scss';
import coverEmpresa from '../assets/img/cover-empresa.jpg';
import { Link } from 'react-router-dom';

const Empresa = () => {
	return (
		<section className='empresa'>
			<img src={coverEmpresa} alt='Motor' className='coverEmpresa' />

			<div className='empresa__texts-container'>
				<h1 className='empresa__main-title'>
					<span>OM</span>TECH
				</h1>
				<p>
					Somos <span>especialistas</span> en reparación,
					mantenimiento e instalación de <span>motores</span> navales
					e industriales.
				</p>
				<p>
					Realizamos intervenciones a <span>nivel global</span>:
					Mozambique, Mauritania, Senegal, Ecuador, Cabo Verde, Costa
					de Marfil, Namibia,...
				</p>
				<p>
					Trabajamos con <span>marcas de primer nivel</span>:
					Wärtsilä, MWM-Deuzt, Man, Mak, Yammar, Mitsubishi, Volvo
					Penta, Pegaso, Scania, Badouin,...
				</p>
				<p>
					Contamos con más de <span>20 años de experiencia</span>.
				</p>
			</div>

			<Link to='/contacto' className='primary_button'>
				Contáctanos
			</Link>
		</section>
	);
};

export default Empresa;
